import '../Table.css';
import 'ka-table/style.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStateContext } from '../../../contexts/ContextProvider';

import { Header } from '../../../components';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import CircularProgress from '@mui/material/CircularProgress';
import { modalInitialStyle, formatDate, helperTextsForInputs } from '../../../data/buildData';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const PhoneDetails = () => {
  const { currentColor, loggedIn, admin, loadingIndicatorActive, setLoadingIndicatorActive, reactQuillAllFormats, reactQuillAllModules } = useStateContext();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const { phoneID } = useParams();

  let [phone, setPhone] = useState("");
  let [phoneError, setPhoneError] = useState({
    error: false,
    errorText: ""
  });

  let [status, setStatus] = useState("");
  let [statusError, setStatusError] = useState({
    error: false,
    errorText: ""
  });

  const setPhoneDetails = (phoneDetails) => {
    setPhone(phoneDetails.phone);
    setStatus(phoneDetails.status);
  }

  const resetErrorForField = (field) => {
    if (field === 0) {
      setPhoneError({
        error: false,
        errorText: ""
      });
    }
    if (field === 1) {
      setStatusError({
        error: false,
        errorText: ""
      });
    }
  }

  const [openEditPhoneModal, setOpenEditPhoneModal] = useState(false);
  const handleOpenEditPhoneModal = () => {
    setOpenEditPhoneModal(true);
  }

  const handleCloseEditPhoneModal = () => {
    setOpenEditPhoneModal(false);
  }

  const setEditPhoneFieldsError = () => {

    if (phone == ""){
      setPhoneError({
        error: true,
        errorText: helperTextsForInputs.phoneError
      });
    } else if (status == ""){
      setStatusError({
        error: true,
        errorText: helperTextsForInputs.phoneStatusError
      });
    }
  }

  const editPhone = async () => {
    if (phone != "" && status != "") {
      setLoadingIndicatorActive(true);
      try {
        const editPhoneResponse = await axiosPrivate.post('/api/v1/phones/edit-phone', {
          phoneID,
          phone,
          status,
        },
          { headers: { 'Content-Type': 'application/json' } });

        console.log(editPhoneResponse.data)
        setLoadingIndicatorActive(false);

        if (editPhoneResponse.data.success) {
          handleCloseEditPhoneModal();
          navigate("/phones");
        }
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
      
    } else {
      setEditPhoneFieldsError();
    }
  }

  const cancelEditNewPhone = async () => {
    navigate('/phones');
  }

  useEffect(() => {
    setLoadingIndicatorActive(true);
    const getPhoneDetails = async () => {
      setLoadingIndicatorActive(true);
      try {
        const phoneDetailsResponse = await axiosPrivate.post('/api/v1/phones/details', { phoneID },
          { headers: { 'Content-Type': 'application/json' } });
        console.log(phoneDetailsResponse.data)
        if (phoneDetailsResponse.data.success) {
          setPhoneDetails(phoneDetailsResponse.data.data);
        }
        setLoadingIndicatorActive(false);
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
    }
    if (!loggedIn) {
      navigate("/login");
    } else {
      getPhoneDetails();
    }
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Phones" title="Edit Phone" />
      {/* Edit Phone Modal */}
      <Modal
        id="editPhoneModal"
        open={openEditPhoneModal}
        onClose={handleCloseEditPhoneModal}
        aria-labelledby="edit-phone-modal-title"
        aria-describedby="edit-phone-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="edit-phone-modal-title" variant="h6" component="h2">
            Edit Phone
          </Typography>
          <Typography id="edit-phone-modal-description" sx={{ mt: 2 }}>
            Are you sure you wish to edit this phone?
          </Typography>
          <Box className='flex-wrap mt-5' >
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseEditPhoneModal}>Cancel</Button>
              <button
                type="button"
                id="editPhoneButton"
                onClick={() => editPhone()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Edit Phone
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      {loadingIndicatorActive &&
        // display: 'flex', justifyContent: 'center', alignItems: 'center'
        <Box sx={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          position: "fixed",
          height: "100%",
          width: "100%",
          top: "0",
          left: "0",
          "backgroundColor": "rgba(255,255,255,0.5)",
          "z-index": "9999",
          "paddingTop": "10px"
        }} >
          <CircularProgress />
        </Box>
      }
      <div className='p-10'>
        <Box className='flex-wrap mt-5' >

          <FormControl sx={{ m: 1, width: "100%" }}>
            <TextField
              error={phoneError.error}
              labelId="phone-label"
              label="Phone"
              helperText={phoneError.errorText}
              value={phone}
              onChange={(e) => {
                resetErrorForField(0);
                setPhone(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <TextField
              error={statusError.error}
              labelId="status-label"
              label="Status"
              helperText={statusError.errorText}
              value={status}
              onChange={(e) => {
                resetErrorForField(1);
                setStatus(e.target.value);
              }}
            />
          </FormControl>
         
          <div className='flex justify-between'>
            <Button color='secondary' onClick={cancelEditNewPhone}>Cancel</Button>
            <button
              type="button"
              id="editPhoneButton"
              onClick={() => handleOpenEditPhoneModal()}
              style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
              className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
            >
              Edit Phone
            </button>
          </div>
        </Box>
      </div>

    </div>
  );
};

export default PhoneDetails;

import '../Table.css';
import 'ka-table/style.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStateContext } from '../../../contexts/ContextProvider';

import { Header } from '../../../components';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import CircularProgress from '@mui/material/CircularProgress';
import { modalInitialStyle, formatDate, helperTextsForInputs } from '../../../data/buildData';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const OfferDetails = () => {
  const { currentColor, loggedIn, admin, loadingIndicatorActive, setLoadingIndicatorActive, reactQuillAllFormats, reactQuillAllModules } = useStateContext();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const { offerID } = useParams();

  const setOfferDetails = (offerDetails) => {
    setOfferId(offerDetails.offerId)
    setOfferType(offerDetails.offerType)
    setOfferAgency(offerDetails.offerAgency)
    setOfferActive(offerDetails.active)
    setOfferClickLink(offerDetails.offerClickLink)
    setOfferSubmissionLink(offerDetails.offerSubmissionLink)
    setOfferSubmissionCommands(offerDetails.submissionCommands)
  }

  let [offerId, setOfferId] = useState("");
  let [offerIdError, setOfferIdError] = useState({
    error: false,
    errorText: ""
  });

  let [offerType, setOfferType] = useState("");
  let [offerTypeError, setOfferTypeError] = useState({
    error: false,
    errorText: ""
  });

  let [offerAgency, setOfferAgency] = useState("");
  let [offerAgencyError, setOfferAgencyError] = useState({
    error: false,
    errorText: ""
  });

  let [offerActive, setOfferActive] = useState(false);

  let [offerClickLink, setOfferClickLink] = useState("");
  let [offerSubmissionLink, setOfferSubmissionLink] = useState("");
  let [offerSubmissionCommands, setOfferSubmissionCommands] = useState([]);

  const resetErrorForField = (field) => {
    if (field === 0) {
      setOfferIdError({
        error: false,
        errorText: ""
      });
    }

    if (field === 1) {
      setOfferAgencyError({
        error: false,
        errorText: ""
      });
    }

    if (field === 2) {
      setOfferTypeError({
        error: false,
        errorText: ""
      });
    }
  }

  const handleChangeActiveCheckbox = (event) => {
    setOfferActive(event.target.checked);
  }

  const [openEditOfferModal, setOpenEditOfferModal] = useState(false);
  const handleOpenEditOfferModal = () => {
    setOpenEditOfferModal(true);
  }

  const handleCloseEditOfferModal = () => {
    setOpenEditOfferModal(false);
  }

  const setEditOfferFieldsError = () => {

    if (offerId == "") {
      setOfferIdError({
        error: true,
        errorText: helperTextsForInputs.offerIdError
      });
    } else if (offerType == "") {
      setOfferTypeError({
        error: true,
        errorText: helperTextsForInputs.offerTypeError
      });
    } else if (offerAgency == "") {
      setOfferAgencyError({
        error: true,
        errorText: helperTextsForInputs.offerAgencyError
      });
    }
  }

  const editOffer = async () => {
    if (offerId != "" && offerType != "" && offerAgency != "") {
      setLoadingIndicatorActive(true);
      try {
        console.log(offerID)
        console.log(offerId)
        console.log(offerAgency)
        console.log(offerType)
        console.log(offerActive)
        console.log(offerClickLink)
        console.log(offerSubmissionLink)
        console.log(offerSubmissionCommands)
        const editOfferResponse = await axiosPrivate.post('/api/v1/offers/edit-offer', {
          offerID,
          offerId,
          offerAgency,
          offerType,
          offerActive,
          offerClickLink,
          offerSubmissionLink,
          submissionCommands: offerSubmissionCommands
        },
          { headers: { 'Content-Type': 'application/json' } });

        console.log(editOfferResponse.data)
        setLoadingIndicatorActive(false);

        if (editOfferResponse.data.success) {
          handleCloseEditOfferModal();
          navigate("/offers");
        }
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }

    } else {
      setEditOfferFieldsError()
    }
  }

  const cancelEditNewOffer = async () => {
    navigate('/offers');
  }

  useEffect(() => {
    setLoadingIndicatorActive(true);
    const getOfferDetails = async () => {
      setLoadingIndicatorActive(true);
      try {
        const offerDetailsResponse = await axiosPrivate.post('/api/v1/offers/details', { offerID },
          { headers: { 'Content-Type': 'application/json' } });
        console.log(offerDetailsResponse.data)
        if (offerDetailsResponse.data.success) {
          setOfferDetails(offerDetailsResponse.data.data);
        }
        setLoadingIndicatorActive(false);
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
    }
    if (!loggedIn) {
      navigate("/login");
    } else {
      getOfferDetails();
    }
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Offers" title="Edit Offer" />
      {/* Edit Offer Modal */}
      <Modal
        id="editOfferModal"
        open={openEditOfferModal}
        onClose={handleCloseEditOfferModal}
        aria-labelledby="edit-offer-modal-title"
        aria-describedby="edit-offer-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="edit-offer-modal-title" variant="h6" component="h2">
            Edit Offer
          </Typography>
          <Typography id="edit-offer-modal-description" sx={{ mt: 2 }}>
            Are you sure you wish to edit this offer?
          </Typography>
          <Box className='flex-wrap mt-5' >
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseEditOfferModal}>Cancel</Button>
              <button
                type="button"
                id="editOfferButton"
                onClick={() => editOffer()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Edit Offer
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      {loadingIndicatorActive &&
        // display: 'flex', justifyContent: 'center', alignItems: 'center'
        <Box sx={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          position: "fixed",
          height: "100%",
          width: "100%",
          top: "0",
          left: "0",
          "backgroundColor": "rgba(255,255,255,0.5)",
          "z-index": "9999",
          "paddingTop": "10px"
        }} >
          <CircularProgress />
        </Box>
      }
      <div className='flex justify-end mb-10 -mt-20 mr-10 md:mr-20'>

      </div>

      <div className='p-10'>
        <Box className='flex-wrap mt-5' >

          <FormControl sx={{ m: 1, width: "100%" }}>

            <TextField
              error={offerIdError.error}
              labelId="offer-id"
              label="Offer Id"
              helperText={offerIdError.errorText}
              value={offerId}
              onChange={(e) => {
                resetErrorForField(0);
                setOfferId(e.target.value);
              }}
            />
          </FormControl>

          <FormControl sx={{ m: 1, width: "100%" }}>

            <TextField
              error={offerAgencyError.error}
              labelId="offer-agency"
              label="Offer Agency"
              helperText={offerAgencyError.errorText}
              value={offerAgency}
              onChange={(e) => {
                resetErrorForField(1);
                setOfferAgency(e.target.value);
              }}
            />
          </FormControl>

          <FormControl sx={{ m: 1, width: "100%" }}>

            <TextField
              error={offerTypeError.error}
              labelId="offer-type"
              label="Offer Type"
              helperText={offerTypeError.errorText}
              value={offerType}
              onChange={(e) => {
                resetErrorForField(2);
                setOfferType(e.target.value);
              }}
            />
          </FormControl>

          <FormControl sx={{ m: 1, width: "100%" }}>

            <TextField
              labelId="offer-click-link"
              label="Offer Click Link"
              value={offerClickLink}
              onChange={(e) => {
                setOfferClickLink(e.target.value);
              }}
            />
          </FormControl>

          <FormControl sx={{ m: 1, width: "100%" }}>

            <TextField
              labelId="offer-submission-link"
              label="Offer Submission Link"
              value={offerSubmissionLink}
              onChange={(e) => {
                setOfferSubmissionLink(e.target.value);
              }}
            />
          </FormControl>
          
          <FormGroup>
            <FormControlLabel control={
              <Checkbox
                checked={offerActive}
                onChange={handleChangeActiveCheckbox}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label="Active" />
          </FormGroup>


          <div className='flex justify-between'>
            <Button color='secondary' onClick={cancelEditNewOffer}>Cancel</Button>
            <button
              type="button"
              id="editOfferButton"
              onClick={() => handleOpenEditOfferModal()}
              style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
              className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
            >
              Edit Offer
            </button>
          </div>
        </Box>
      </div>

    </div>
  );
};

export default OfferDetails;

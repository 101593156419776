import '../Table.css';
import 'ka-table/style.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStateContext } from '../../../contexts/ContextProvider';

import { Header } from '../../../components';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import CircularProgress from '@mui/material/CircularProgress';
import { modalInitialStyle, formatDate, helperTextsForInputs } from '../../../data/buildData';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const EmailDetails = () => {
  const { currentColor, loggedIn, admin, loadingIndicatorActive, setLoadingIndicatorActive, reactQuillAllFormats, reactQuillAllModules } = useStateContext();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const { emailID } = useParams();

  let [email, setEmail] = useState("");
  let [emailError, setEmailError] = useState({
    error: false,
    errorText: ""
  });

  let [status, setStatus] = useState("");
  let [statusError, setStatusError] = useState({
    error: false,
    errorText: ""
  });

  let [provider, setProvider] = useState("");
  let [providerError, setProviderError] = useState({
    error: false,
    errorText: ""
  });

  let [dateSignedUp, setDateSignedUp] = useState("");

  const setEmailDetails = (emailDetails) => {
    setEmail(emailDetails.email);
    setStatus(emailDetails.status);
    setProvider(emailDetails.provider);
    setDateSignedUp(emailDetails.dateSignedUp);
  }

  const resetErrorForField = (field) => {
    if (field === 0) {
      setEmailError({
        error: false,
        errorText: ""
      });
    }
    if (field === 1) {
      setProviderError({
        error: false,
        errorText: ""
      });
    }
    if (field === 2) {
      setStatusError({
        error: false,
        errorText: ""
      });
    }
  }

  const [openEditEmailModal, setOpenEditEmailModal] = useState(false);
  const handleOpenEditEmailModal = () => {
    setOpenEditEmailModal(true);
  }

  const handleCloseEditEmailModal = () => {
    setOpenEditEmailModal(false);
  }

  const setEditEmailFieldsError = () => {

    if (email == ""){
      setEmailError({
        error: true,
        errorText: helperTextsForInputs.emailError
      });
    } else if (provider == ""){
      setProviderError({
        error: true,
        errorText: helperTextsForInputs.emailProviderError
      });
    } else if (status == ""){
      setStatusError({
        error: true,
        errorText: helperTextsForInputs.emailStatusError
      });
    }
  }

  const editEmail = async () => {
    if (email != "" && provider != "" && status != "") {
      setLoadingIndicatorActive(true);
      try {
        const editEmailResponse = await axiosPrivate.post('/api/v1/emails/edit-email', {
          emailID,
          email,
          provider,
          status,
        },
          { headers: { 'Content-Type': 'application/json' } });

        console.log(editEmailResponse.data)
        setLoadingIndicatorActive(false);

        if (editEmailResponse.data.success) {
          handleCloseEditEmailModal();
          navigate("/emails");
        }
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
      
    } else {
      setEditEmailFieldsError();
    }
  }

  const cancelEditNewEmail = async () => {
    navigate('/emails');
  }

  useEffect(() => {
    setLoadingIndicatorActive(true);
    const getEmailDetails = async () => {
      setLoadingIndicatorActive(true);
      try {
        const emailDetailsResponse = await axiosPrivate.post('/api/v1/emails/details', { emailID },
          { headers: { 'Content-Type': 'application/json' } });
        console.log(emailDetailsResponse.data)
        if (emailDetailsResponse.data.success) {
          setEmailDetails(emailDetailsResponse.data.data);
        }
        setLoadingIndicatorActive(false);
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
    }
    if (!loggedIn) {
      navigate("/login");
    } else {
      getEmailDetails();
    }
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Emails" title="Edit Email" />
      {/* Edit Email Modal */}
      <Modal
        id="editEmailModal"
        open={openEditEmailModal}
        onClose={handleCloseEditEmailModal}
        aria-labelledby="edit-email-modal-title"
        aria-describedby="edit-email-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="edit-email-modal-title" variant="h6" component="h2">
            Edit Email
          </Typography>
          <Typography id="edit-email-modal-description" sx={{ mt: 2 }}>
            Are you sure you wish to edit this email?
          </Typography>
          <Box className='flex-wrap mt-5' >
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseEditEmailModal}>Cancel</Button>
              <button
                type="button"
                id="editEmailButton"
                onClick={() => editEmail()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Edit Email
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      {loadingIndicatorActive &&
        // display: 'flex', justifyContent: 'center', alignItems: 'center'
        <Box sx={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          position: "fixed",
          height: "100%",
          width: "100%",
          top: "0",
          left: "0",
          "backgroundColor": "rgba(255,255,255,0.5)",
          "z-index": "9999",
          "paddingTop": "10px"
        }} >
          <CircularProgress />
        </Box>
      }
      <div className='p-10'>
        <Box className='flex-wrap mt-5' >

          <FormControl sx={{ m: 1, width: "100%" }}>
            <TextField
              error={emailError.error}
              labelId="email-label"
              label="Email"
              helperText={emailError.errorText}
              value={email}
              onChange={(e) => {
                resetErrorForField(0);
                setEmail(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <TextField
              error={providerError.error}
              labelId="provider-label"
              label="Provider"
              helperText={providerError.errorText}
              value={provider}
              onChange={(e) => {
                resetErrorForField(1);
                setProvider(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <TextField
              error={statusError.error}
              labelId="status-label"
              label="Status"
              helperText={statusError.errorText}
              value={status}
              onChange={(e) => {
                resetErrorForField(2);
                setStatus(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <span><strong>Date Signed Up: </strong><p>{formatDate(dateSignedUp)}</p></span>
          </FormControl>
          <div className='flex justify-between'>
            <Button color='secondary' onClick={cancelEditNewEmail}>Cancel</Button>
            <button
              type="button"
              id="editEmailButton"
              onClick={() => handleOpenEditEmailModal()}
              style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
              className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
            >
              Edit Email
            </button>
          </div>
        </Box>
      </div>

    </div>
  );
};

export default EmailDetails;

import '../Table.css';
import 'ka-table/style.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';

import { Header } from '../../../components';
import Box from '@mui/material/Box';
import { MdAdd } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

import { FaFileCsv } from 'react-icons/fa';
import { RiPlayListAddFill } from 'react-icons/ri';
import { CSVLink } from 'react-csv';

import { updateData, search } from 'ka-table/actionCreators';
import { kaReducer, Table } from 'ka-table';
import { DataType, EditingMode, SortingMode, PagingPosition } from 'ka-table/enums';
import { kaPropsUtils } from 'ka-table/utils';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import CircularProgress from '@mui/material/CircularProgress';
import { modalInitialStyle, formatDate } from '../../../data/buildData';


import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const Phones = () => {
  const { currentColor, loggedIn, admin, loadingIndicatorActive, setLoadingIndicatorActive } = useStateContext();
  const [openBulkAddPhonesModal, setOpenBulkAddPhonesModal] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  let [phonesArray, setPhonesArray] = useState([]);

  let [phoneIDToDelete, setPhoneIDToDelete] = useState("");

  let [uploadCSVFile, setUploadCSVFile] = useState(undefined);
  let [uploadCSVFileName, setUploadCSVFileName] = useState("");

  const handleChangeFileUpload = (e) => {
    setUploadCSVFile(e.target.files[0]);
    setUploadCSVFileName(e.target.files[0].name);
  }

  const handleOpenBulkAddPhonesModal = () => {
    setOpenBulkAddPhonesModal(true);
  }

  const handleCloseBulkAddPhonesModal = () => {
    setUploadCSVFile(undefined);
    setUploadCSVFileName("");
    setOpenBulkAddPhonesModal(false);
  }

  const bulkAddPhones = async () => {
    if (uploadCSVFile !== undefined) {
      const formData = new FormData();
      formData.append("file", uploadCSVFile);
      formData.append("fileName", uploadCSVFileName);
      try {
        const bulkAddPhonesResponse = await axiosPrivate.post('/api/v1/phones/bulk-add-phones',
          formData,
          { headers: { 'Content-Type': 'application/json' } }
        )

        if (bulkAddPhonesResponse.data.success) {
          console.log("success");
          window.location.reload(false);
        } else {
          console.log("Failure");
        }

      } catch (err) {
        if (!err?.response) {
          console.log("no err response ", err);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
      }
    }
  }

  const [openDeletePhoneModal, setOpenDeletePhoneModal] = useState(false);
  const handleOpenDeletePhoneModal = (phoneID) => {
    setPhoneIDToDelete(phoneID);
    setOpenDeletePhoneModal(true);
  }

  const handleCloseDeletePhoneModal = () => {
    setPhoneIDToDelete("");
    setOpenDeletePhoneModal(false);
  }

  const deletePhone = async () => {
    if (phoneIDToDelete != "" && phoneIDToDelete != null) {
      setLoadingIndicatorActive(true);
      try {
        const deletePhoneResponse = await axiosPrivate.post('/api/v1/phones/delete-phone', { phoneIDToDelete },
          { headers: { 'Content-Type': 'application/json' } });
        console.log(deletePhoneResponse.data)
        if (deletePhoneResponse.data.success) {
          setPhonesArray(deletePhoneResponse.data.data);

          dispatch(updateData(deletePhoneResponse.data.data));
        }
        handleCloseDeletePhoneModal();
        setLoadingIndicatorActive(false);
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
    }
  }

  const addNewPhone = async () => {
    navigate('/phones/new-phone');
  }
  const editPhone = async (phoneID) => {
    navigate('/phones/' + phoneID);
  }

  const ROW_MOUSE_ENTER = 'ROW_MOUSE_ENTER';
  const ROW_MOUSE_LEAVE = 'ROW_MOUSE_LEAVE';

  const tablePropsInit = {
    columns: [
      { key: '_id', title: 'ID', dataType: DataType.String, colGroup: { style: { minWidth: 120 } }, width: 120, },
      { key: 'phone', title: 'Phone', dataType: DataType.String, colGroup: { style: { minWidth: 130 } }, width: 130, },
      { key: 'status', title: 'Status', dataType: DataType.String, colGroup: { style: { minWidth: 100 } }, width: 100, },
      { key: 'Actions', title: 'Actions', dataType: DataType.Object, colGroup: { style: { minWidth: 200 } }, width: 200, },
    ],
    data: phonesArray,
    editingMode: EditingMode.None,
    rowKeyField: '_id',
    sortingMode: SortingMode.Single,
  };
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch = (action) => {
    changeTableProps((prevState) => kaReducer(prevState, action));
  };

  useEffect(() => {
    setLoadingIndicatorActive(true);
    const getPhones = async () => {
      setLoadingIndicatorActive(true);
      try {
        const phonesResponse = await axiosPrivate.post('/api/v1/phones/all-phones', {},
          { headers: { 'Content-Type': 'application/json' } });
        console.log(phonesResponse.data)
        if (phonesResponse.data.success) {
          setPhonesArray(phonesResponse.data.data);

          dispatch(updateData(phonesResponse.data.data));
        }
        setLoadingIndicatorActive(false);
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
    }
    if (!loggedIn) {
      navigate("/login");
    } else {
      getPhones();
    }
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Phones" title="ALL" />
      {/* Bulk Add Bot User Modal */}
      <Modal
        id="bulkAddPhonesModal"
        open={openBulkAddPhonesModal}
        onClose={handleCloseBulkAddPhonesModal}
        aria-labelledby="bulk-add-phones-modal-title"
        aria-describedby="bulk-add-phones-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="bulk-add-phones-modal-title" variant="h6" component="h2">
            Bulk Add Phones
          </Typography>
          <Typography id="bulk-add-phone-modal-description" sx={{ mt: 2 }}>
            Add a CSV file of new Phones.
          </Typography>
          <Box className='flex-wrap mt-5' >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <input type="file" onChange={handleChangeFileUpload} />
            </FormControl>
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseBulkAddPhonesModal}>Cancel</Button>
              <button
                type="button"
                id="bulkAddPhonesButton"
                onClick={() => bulkAddPhones()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Upload Phones
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      {/* Delete Phone Modal */}
      <Modal
        id="deletePhoneModal"
        open={openDeletePhoneModal}
        onClose={handleCloseDeletePhoneModal}
        aria-labelledby="delete-phone-modal-title"
        aria-describedby="delete-phone-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="delete-phone-modal-title" variant="h6" component="h2">
            Delete Phone
          </Typography>
          <Typography id="delete-phone-modal-description" sx={{ mt: 2 }}>
            Are you sure you wish to remove this phone? <span className='text-rose-600'>This action is irreversible.</span>
          </Typography>
          <Box className='flex-wrap mt-5' >
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseDeletePhoneModal}>Cancel</Button>
              <button
                type="button"
                id="deletePhoneButton"
                onClick={() => deletePhone()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Delete Phone
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      {loadingIndicatorActive &&
        // display: 'flex', justifyContent: 'center', alignItems: 'center'
        <Box sx={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          position: "fixed",
          height: "100%",
          width: "100%",
          top: "0",
          left: "0",
          "backgroundColor": "rgba(255,255,255,0.5)",
          "z-index": "9999",
          "paddingTop": "10px"
        }} >
          <CircularProgress />
        </Box>
      }
      <div className='flex justify-end mb-10 -mt-20 mr-10 md:mr-20'>
        <TooltipComponent content="Add New Phone" position="BottomCenter">
          <button
            type="button"
            id="addNewPhoneButton"
            onClick={() => addNewPhone()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
          >
            <MdAdd />
          </button>
        </TooltipComponent>
        &nbsp;
        <TooltipComponent content="Bulk Add Phones" position="BottomCenter">
          <button
            type="button"
            id="bulkAddPhonesButton"
            onClick={() => handleOpenBulkAddPhonesModal()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray `}
          >
            <RiPlayListAddFill />
          </button>
        </TooltipComponent>
        &nbsp;
        <CSVLink
          data={kaPropsUtils.getData(tableProps)}
          headers={tableProps.columns.map(c => ({ label: c.title, key: c.key }))}
          filename={new Date().toLocaleDateString() + '-Phones.csv'}
          enclosingCharacter={''}
          separator={';'}>
          <TooltipComponent content="Download .CSV" position="BottomCenter">
            <button
              type="button"
              style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
              className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
            >
              <FaFileCsv />
            </button>

          </TooltipComponent>
        </CSVLink>
      </div>

      <Table
        {...tableProps}
        dispatch={dispatch}
        childComponents={{
          noDataRow: {
            content: () => 'No Phones Found'
          },
          dataRow: {
            elementAttributes: (props) => ({
              onMouseEnter: (event, extendedEvent) => {
                const {
                  childProps: {
                    rowKeyValue,
                  },
                  dispatch,
                } = extendedEvent;
                dispatch({ type: ROW_MOUSE_ENTER, rowKeyValue });
              },
              onMouseLeave: (event, { dispatch }) => {
                dispatch({ type: ROW_MOUSE_LEAVE });
              }
            })
          },
          cell: {
            elementAttributes: () => ({
              // className: 'my-cell-class',
              onClick: (e, extendedEvent) => {
                // const { childProps: { dispatch } } = extendedEvent;
                // dispatch({ type: 'MY_CELL_onClick', ...{ extendedEvent }});
                if (extendedEvent.childProps.column.key !== "Actions") {
                  // console.log("clicked row " + extendedEvent.childProps.rowKeyValue)
                  // navigateToClientDetailsWithID(extendedEvent.childProps.rowKeyValue);
                }
              },
            })
          },
          cellText: {
            content: (props) => {
              switch (props.column.key) {
                case 'Actions': return (
                  <div className='flex'>
                    <TooltipComponent content="Edit Phone" position="BottomCenter">
                      <button
                        type="button"
                        id="editPhoneButton"
                        onClick={() => editPhone(props.rowData._id)}
                        style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
                        className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
                      >
                        <AiFillEdit />
                      </button>
                    </TooltipComponent>
                    &nbsp;
                    <TooltipComponent content="Delete Phone" position="BottomCenter">
                      <button
                        type="button"
                        id="deletePhoneButton"
                        onClick={() => handleOpenDeletePhoneModal(props.rowData._id)}
                        style={{ backgroundColor: "#FF5C8E", color: "white", borderRadius: "50%" }}
                        className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
                      >
                        <AiFillDelete />
                      </button>
                    </TooltipComponent>
                  </div>
                );
              }
            }
          }
        }}
      />
    </div>
  );
};

export default Phones;

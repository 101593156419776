import '../Table.css';
import 'ka-table/style.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStateContext } from '../../../contexts/ContextProvider';

import { Header } from '../../../components';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import CircularProgress from '@mui/material/CircularProgress';
import { modalInitialStyle, formatDate, helperTextsForInputs } from '../../../data/buildData';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const ActionDetails = () => {
  const { currentColor, loggedIn, admin, loadingIndicatorActive, setLoadingIndicatorActive, reactQuillAllFormats, reactQuillAllModules } = useStateContext();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const { actionID } = useParams();


  const [allLists, setAllLists] = useState([]);
  const [selectedListID, setSelectedListID] = useState("");
  const [selectedListName, setSelectedListName] = useState("");

  const [autoConfirmationMessage, setAutoConfirmationMessage] = useState("Thank you for joining our list");
  const [complianceMessage, setComplianceMessage] = useState("Reply STOP to unsubscribe or HELP for help. 4 msgs per month, Msg&Data rates may apply.");

  const setActionDetails = (actionDetails) => {
    setSelectedListID(actionDetails.action.addToListID);
    setSelectedListName(actionDetails.action.addToListName);
    setNewActionName(actionDetails.action.action);
    setCheckedIncludeAutoConfirmationMessageCheckbox(actionDetails.action.sendAutoConfirmationMessage);
    setAutoConfirmationMessage(actionDetails.action.autoConfirmationMessage);
    setCheckedIncludeComplianceMessageCheckbox(actionDetails.action.sendComplianceMessage);
    setComplianceMessage(actionDetails.action.complianceMessage);
    setCheckedUseSMSCheckbox(actionDetails.action.sendSMS);
    setCheckedUseWhatsappCheckbox(actionDetails.action.sendWhatsApp);
    setAllLists(actionDetails.allLists);
  }

  let [checkedIncludeAutoConfirmationMessageCheckbox, setCheckedIncludeAutoConfirmationMessageCheckbox] = useState(false);
  const handleChangeIncludeAutoConfirmationMessageCheckbox = (event) => {
    setCheckedIncludeAutoConfirmationMessageCheckbox(event.target.checked);
  }

  let [checkedIncludeComplianceMessageCheckbox, setCheckedIncludeComplianceMessageCheckbox] = useState(false);
  const handleChangeIncludeComplianceMessageCheckbox = (event) => {
    setCheckedIncludeComplianceMessageCheckbox(event.target.checked);
  }

  let [checkedUseSMSCheckbox, setCheckedUseSMSCheckbox] = useState(false);
  const handleChangeUseSMSCheckbox = (event) => {
    setCheckedUseSMSCheckbox(event.target.checked);
  }

  let [checkedUseWhatsappCheckbox, setCheckedUseWhatsappCheckbox] = useState(false);
  const handleChangeUseWhatsappCheckbox = (event) => {
    setCheckedUseWhatsappCheckbox(event.target.checked);
  }

  let [newActionName, setNewActionName] = useState("");
  let [newActionNameError, setNewActionNameError] = useState({
    error: false,
    errorText: ""
  });

  const resetErrorForField = (field) => {
    if (field === 0) {
      setNewActionNameError({
        error: false,
        errorText: ""
      });
    }
  }

  const handleChangeSelectedList = (selectedListName) => {
    let selectedListID = "";
    for (var i = 0; i < allLists.length; i++) {
      if (allLists[i].name === selectedListName) {
        selectedListID = allLists[i]._id;
        break;
      }
    }
    setSelectedListID(selectedListID);
    setSelectedListName(selectedListName);
  }

  const [openEditActionModal, setOpenEditActionModal] = useState(false);
  const handleOpenEditActionModal = () => {
    setOpenEditActionModal(true);
  }

  const handleCloseEditActionModal = () => {
    setOpenEditActionModal(false);
  }

  const editAction = async () => {
    if (newActionName != "") {
      if (selectedListID != "" && selectedListName != "") {
        setLoadingIndicatorActive(true);
        try {
          const editActionResponse = await axiosPrivate.post('/api/v1/actions/edit-action', {
            actionID,
            selectedListID,
            selectedListName,
            newActionName,
            checkedIncludeAutoConfirmationMessageCheckbox,
            autoConfirmationMessage,
            checkedIncludeComplianceMessageCheckbox,
            complianceMessage,
            checkedUseSMSCheckbox,
            checkedUseWhatsappCheckbox
          },
            { headers: { 'Content-Type': 'application/json' } });

          console.log(editActionResponse.data)
          setLoadingIndicatorActive(false);

          if (editActionResponse.data.success) {
            handleCloseEditActionModal();
            navigate("/actions");
          }
        } catch (err) {
          if (!err?.response) {
            console.log(err.response);
          } else if (err.response?.status === 400) {

          } else if (err.response?.status === 401) {

          } else if (err.response?.status === 500) {

          } else {

          }
          setLoadingIndicatorActive(false);
        }
      }
    } else {
      setNewActionNameError({
        error: true,
        errorText: helperTextsForInputs.newActionNameError
      });
    }
  }

  const cancelEditNewAction = async () => {
    navigate('/actions');
  }

  useEffect(() => {
    setLoadingIndicatorActive(true);
    const getActionDetails = async () => {
      setLoadingIndicatorActive(true);
      try {
        const actionDetailsResponse = await axiosPrivate.post('/api/v1/actions/details', { actionID },
          { headers: { 'Content-Type': 'application/json' } });
        console.log(actionDetailsResponse.data)
        if (actionDetailsResponse.data.success) {
          setActionDetails(actionDetailsResponse.data.data);
        }
        setLoadingIndicatorActive(false);
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
    }
    if (!loggedIn) {
      navigate("/login");
    } else {
      getActionDetails();
    }
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Actions" title="Edit Action" />
      {/* Edit Action Modal */}
      <Modal
        id="editActionModal"
        open={openEditActionModal}
        onClose={handleCloseEditActionModal}
        aria-labelledby="edit-action-modal-title"
        aria-describedby="edit-action-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="edit-action-modal-title" variant="h6" component="h2">
            Edit Action
          </Typography>
          <Typography id="edit-action-modal-description" sx={{ mt: 2 }}>
            Are you sure you wish to edit this action?
          </Typography>
          <Box className='flex-wrap mt-5' >
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseEditActionModal}>Cancel</Button>
              <button
                type="button"
                id="editActionButton"
                onClick={() => editAction()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Edit Action
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      {loadingIndicatorActive &&
        // display: 'flex', justifyContent: 'center', alignItems: 'center'
        <Box sx={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          position: "fixed",
          height: "100%",
          width: "100%",
          top: "0",
          left: "0",
          "backgroundColor": "rgba(255,255,255,0.5)",
          "z-index": "9999",
          "paddingTop": "10px"
        }} >
          <CircularProgress />
        </Box>
      }
      <div className='flex justify-end mb-10 -mt-20 mr-10 md:mr-20'>

      </div>

      <div className='p-10'>
        <Box className='flex-wrap mt-5' >

          <FormControl sx={{ m: 1, width: "100%" }}>

            <TextField
              error={newActionNameError.error}
              labelId="action-label"
              label="Action"
              helperText={newActionNameError.errorText}
              value={newActionName}
              onChange={(e) => {
                resetErrorForField(0);
                setNewActionName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1, width: "100%" }}>
            <InputLabel id="select-list-label">List</InputLabel>
            <Select
              labelId="select-list-label"
              id="select-list"
              value={selectedListName}
              label="List"
              onChange={(e) => handleChangeSelectedList(e.target.value)}
            >
              {allLists.map((item, keyIndex) => {
                return (<MenuItem key={keyIndex} value={item.name}>{item.name}</MenuItem>);
              })}
            </Select>
          </FormControl>

          <FormGroup>
            <FormControlLabel control={
              <Checkbox
                checked={checkedIncludeAutoConfirmationMessageCheckbox}
                onChange={handleChangeIncludeAutoConfirmationMessageCheckbox}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label="Include Auto Confirmation Message" />
          </FormGroup>

          <InputLabel id="auto-confirmation-message-label">Auto Confirmation Message</InputLabel>
          <FormControl sx={{ m: 1, width: "100%" }}>

            <TextField
              labelId="auto-confirmation-message-label"
              id="auto-confirmation-message"
              multiline
              rows={4}
              value={autoConfirmationMessage}
              onChange={(e) => {
                setAutoConfirmationMessage(e.target.value);
              }}
            />
          </FormControl>
          <FormGroup>
            <FormControlLabel control={
              <Checkbox
                checked={checkedIncludeComplianceMessageCheckbox}
                onChange={handleChangeIncludeComplianceMessageCheckbox}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label="Include Compliance Message" />
          </FormGroup>

          <InputLabel id="compliance-message-label">Compliance Message</InputLabel>
          <FormControl sx={{ m: 1, width: "100%" }}>

            <TextField
              labelId="compliance-message-label"
              id="compliance-message"
              multiline
              rows={4}
              value={complianceMessage}
              onChange={(e) => {
                setComplianceMessage(e.target.value);
              }}
            />
          </FormControl>

          <FormGroup>
            <FormControlLabel control={
              <Checkbox
                checked={checkedUseSMSCheckbox}
                onChange={handleChangeUseSMSCheckbox}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label="Use for SMS" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel control={
              <Checkbox
                checked={checkedUseWhatsappCheckbox}
                onChange={handleChangeUseWhatsappCheckbox}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label="Use for Whatsapp" />
          </FormGroup>

          <div className='flex justify-between'>
            <Button color='secondary' onClick={cancelEditNewAction}>Cancel</Button>
            <button
              type="button"
              id="editActionButton"
              onClick={() => handleOpenEditActionModal()}
              style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
              className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
            >
              Edit Action
            </button>
          </div>
        </Box>
      </div>

    </div>
  );
};

export default ActionDetails;

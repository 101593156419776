import '../Table.css';
import 'ka-table/style.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';

import { Header } from '../../../components';
import Box from '@mui/material/Box';
import { MdAdd } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

import { FaFileCsv } from 'react-icons/fa';
import { RiPlayListAddFill } from 'react-icons/ri';
import { CSVLink } from 'react-csv';

import { updateData, search } from 'ka-table/actionCreators';
import { kaReducer, Table } from 'ka-table';
import { DataType, EditingMode, SortingMode, PagingPosition } from 'ka-table/enums';
import { kaPropsUtils } from 'ka-table/utils';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import CircularProgress from '@mui/material/CircularProgress';
import { modalInitialStyle, formatDate } from '../../../data/buildData';


import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const Emails = () => {
  const { currentColor, loggedIn, admin, loadingIndicatorActive, setLoadingIndicatorActive } = useStateContext();
  const [openBulkAddEmailsModal, setOpenBulkAddEmailsModal] = useState(false);
  const [openBulkAddEmailsIRModal, setOpenBulkAddEmailsIRModal] = useState(false);
  const [openResetInUseEmailsModal, setOpenResetInUseEmailsModal] = useState(false);

  const [openBulkAddEmailsMarketBeatModal, setOpenBulkAddEmailsMarketBeatModal] = useState(false);
  const [openBulkAddEmailsLMKRCBModal, setOpenBulkAddEmailsLMKRCBModal] = useState(false);
  const [openBulkAddEmailsFINWSHModal, setOpenBulkAddEmailsFINWSHModal] = useState(false);
  const [openBulkAddEmailsFINPTTModal, setOpenBulkAddEmailsFINPTTModal] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  let [emailsArray, setEmailsArray] = useState([]);

  let [emailIDToDelete, setEmailIDToDelete] = useState("");

  let [uploadCSVFile, setUploadCSVFile] = useState(undefined);
  let [uploadCSVFileName, setUploadCSVFileName] = useState("");

  const handleChangeFileUpload = (e) => {
    setUploadCSVFile(e.target.files[0]);
    setUploadCSVFileName(e.target.files[0].name);
  }

  const handleOpenBulkAddEmailsModal = () => {
    setOpenBulkAddEmailsModal(true);
  }

  const handleCloseBulkAddEmailsModal = () => {
    setUploadCSVFile(undefined);
    setUploadCSVFileName("");
    setOpenBulkAddEmailsModal(false);
  }

  const bulkAddEmails = async () => {
    if (uploadCSVFile !== undefined) {
      const formData = new FormData();
      formData.append("file", uploadCSVFile);
      formData.append("fileName", uploadCSVFileName);
      try {
        const bulkAddEmailsResponse = await axiosPrivate.post('/api/v1/emails/bulk-add-emails',
          formData,
          { headers: { 'Content-Type': 'application/json' } }
        )

        if (bulkAddEmailsResponse.data.success) {
          console.log("success");
          window.location.reload(false);
        } else {
          console.log("Failure");
        }

      } catch (err) {
        if (!err?.response) {
          console.log("no err response ", err);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
      }
    }
  }

  const handleOpenResetInUseEmailsModal = () => {
    setOpenResetInUseEmailsModal(true);
  }

  const handleCloseResetInUseEmailsModal = () => {
    setOpenResetInUseEmailsModal(false);
  }

  const resetInUseEmails = async () => {
    
    try {
      const resetInUseEmailsResponse = await axiosPrivate.post('/api/v1/emails/reset-in-use-emails',
        {},
        { headers: { 'Content-Type': 'application/json' } }
      )

      if (resetInUseEmailsResponse.data.success) {
        console.log("success");
        window.location.reload(false);
      } else {
        console.log("Failure");
      }

    } catch (err) {
      if (!err?.response) {
        console.log("no err response ", err);
      } else if (err.response?.status === 400) {

      } else if (err.response?.status === 401) {

      } else if (err.response?.status === 500) {

      } else {

      }
    }
    
  }

  const handleOpenBulkAddEmailsMarketBeatModal = () => {
    setOpenBulkAddEmailsMarketBeatModal(true);
  }

  const handleCloseBulkAddEmailsMarketBeatModal = () => {
    setUploadCSVFile(undefined);
    setUploadCSVFileName("");
    setOpenBulkAddEmailsMarketBeatModal(false);
  }

  const bulkAddEmailsMarketBeat = async () => {
    if (uploadCSVFile !== undefined) {
      const formData = new FormData();
      formData.append("file", uploadCSVFile);
      formData.append("fileName", uploadCSVFileName);
      try {
        const bulkAddEmailsResponse = await axiosPrivate.post('/api/v1/emails/bulk-add-emails-market-beat',
          formData,
          { headers: { 'Content-Type': 'application/json' } }
        )

        if (bulkAddEmailsResponse.data.success) {
          console.log("success");
          window.location.reload(false);
        } else {
          console.log("Failure");
        }

      } catch (err) {
        if (!err?.response) {
          console.log("no err response ", err);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
      }
    }
  }

  const handleOpenBulkAddEmailsIRModal = () => {
    setOpenBulkAddEmailsIRModal(true);
  }

  

  const handleCloseBulkAddEmailsIRModal = () => {
    setUploadCSVFile(undefined);
    setUploadCSVFileName("");
    setOpenBulkAddEmailsIRModal(false);
  }

  const bulkAddEmailsIR = async () => {
    if (uploadCSVFile !== undefined) {
      const formData = new FormData();
      formData.append("file", uploadCSVFile);
      formData.append("fileName", uploadCSVFileName);
      try {
        const bulkAddEmailsResponse = await axiosPrivate.post('/api/v1/emails/bulk-add-emails-ir',
          formData,
          { headers: { 'Content-Type': 'application/json' } }
        )

        if (bulkAddEmailsResponse.data.success) {
          console.log("success");
          window.location.reload(false);
        } else {
          console.log("Failure");
        }

      } catch (err) {
        if (!err?.response) {
          console.log("no err response ", err);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
      }
    }
  }


  const handleOpenBulkAddEmailsLMKRCBModal = () => {
    setOpenBulkAddEmailsLMKRCBModal(true);
  }

  const handleCloseBulkAddEmailsLMKRCBModal = () => {
    setUploadCSVFile(undefined);
    setUploadCSVFileName("");
    setOpenBulkAddEmailsLMKRCBModal(false);
  }

  const bulkAddEmailsLMKRCB = async () => {
    if (uploadCSVFile !== undefined) {
      const formData = new FormData();
      formData.append("file", uploadCSVFile);
      formData.append("fileName", uploadCSVFileName);
      try {
        const bulkAddEmailsResponse = await axiosPrivate.post('/api/v1/emails/bulk-add-emails-lmk-rcb',
          formData,
          { headers: { 'Content-Type': 'application/json' } }
        )

        if (bulkAddEmailsResponse.data.success) {
          console.log("success");
          window.location.reload(false);
        } else {
          console.log("Failure");
        }

      } catch (err) {
        if (!err?.response) {
          console.log("no err response ", err);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
      }
    }
  }

  const handleOpenBulkAddEmailsFINWSHModal = () => {
    setOpenBulkAddEmailsFINWSHModal(true);
  }

  const handleCloseBulkAddEmailsFINWSHModal = () => {
    setUploadCSVFile(undefined);
    setUploadCSVFileName("");
    setOpenBulkAddEmailsFINWSHModal(false);
  }

  const bulkAddEmailsFINWSH = async () => {
    if (uploadCSVFile !== undefined) {
      const formData = new FormData();
      formData.append("file", uploadCSVFile);
      formData.append("fileName", uploadCSVFileName);
      try {
        const bulkAddEmailsResponse = await axiosPrivate.post('/api/v1/emails/bulk-add-emails-fin-wsh',
          formData,
          { headers: { 'Content-Type': 'application/json' } }
        )

        if (bulkAddEmailsResponse.data.success) {
          console.log("success");
          window.location.reload(false);
        } else {
          console.log("Failure");
        }

      } catch (err) {
        if (!err?.response) {
          console.log("no err response ", err);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
      }
    }
  }

  const handleOpenBulkAddEmailsFINPTTModal = () => {
    setOpenBulkAddEmailsFINPTTModal(true);
  }

  const handleCloseBulkAddEmailsFINPTTModal = () => {
    setUploadCSVFile(undefined);
    setUploadCSVFileName("");
    setOpenBulkAddEmailsFINPTTModal(false);
  }

  const bulkAddEmailsFINPTT = async () => {
    if (uploadCSVFile !== undefined) {
      const formData = new FormData();
      formData.append("file", uploadCSVFile);
      formData.append("fileName", uploadCSVFileName);
      try {
        const bulkAddEmailsResponse = await axiosPrivate.post('/api/v1/emails/bulk-add-emails-fin-ptt',
          formData,
          { headers: { 'Content-Type': 'application/json' } }
        )

        if (bulkAddEmailsResponse.data.success) {
          console.log("success");
          window.location.reload(false);
        } else {
          console.log("Failure");
        }

      } catch (err) {
        if (!err?.response) {
          console.log("no err response ", err);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
      }
    }
  }
  

  const [openDeleteEmailModal, setOpenDeleteEmailModal] = useState(false);
  const handleOpenDeleteEmailModal = (emailID) => {
    setEmailIDToDelete(emailID);
    setOpenDeleteEmailModal(true);
  }

  const handleCloseDeleteEmailModal = () => {
    setEmailIDToDelete("");
    setOpenDeleteEmailModal(false);
  }

  const deleteEmail = async () => {
    if (emailIDToDelete != "" && emailIDToDelete != null) {
      setLoadingIndicatorActive(true);
      try {
        const deleteEmailResponse = await axiosPrivate.post('/api/v1/emails/delete-email', { emailIDToDelete },
          { headers: { 'Content-Type': 'application/json' } });
        console.log(deleteEmailResponse.data)
        if (deleteEmailResponse.data.success) {
          setEmailsArray(deleteEmailResponse.data.data);

          dispatch(updateData(deleteEmailResponse.data.data));
        }
        handleCloseDeleteEmailModal();
        setLoadingIndicatorActive(false);
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
    }
  }

  const addNewEmail = async () => {
    navigate('/emails/new-email');
  }
  const editEmail = async (emailID) => {
    navigate('/emails/' + emailID);
  }

  const ROW_MOUSE_ENTER = 'ROW_MOUSE_ENTER';
  const ROW_MOUSE_LEAVE = 'ROW_MOUSE_LEAVE';

  const tablePropsInit = {
    columns: [
      { key: '_id', title: 'ID', dataType: DataType.String, colGroup: { style: { minWidth: 120 } }, width: 120, },
      { key: 'email', title: 'Email', dataType: DataType.String, colGroup: { style: { minWidth: 130 } }, width: 130, },
      { key: 'provider', title: 'Provider', dataType: DataType.String, colGroup: { style: { minWidth: 100 } }, width: 100, },
      { key: 'status', title: 'Status', dataType: DataType.String, colGroup: { style: { minWidth: 100 } }, width: 100, },
      { key: 'dateSignedUp', title: 'Date Signed Up', dataType: DataType.String, colGroup: { style: { minWidth: 150 } }, width: 150, },
      { key: 'Actions', title: 'Actions', dataType: DataType.Object, colGroup: { style: { minWidth: 200 } }, width: 200, },
    ],
    data: emailsArray,
    editingMode: EditingMode.None,
    rowKeyField: '_id',
    sortingMode: SortingMode.Single,
  };
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch = (action) => {
    changeTableProps((prevState) => kaReducer(prevState, action));
  };

  useEffect(() => {
    setLoadingIndicatorActive(true);
    const getEmails = async () => {
      setLoadingIndicatorActive(true);
      try {
        const emailsResponse = await axiosPrivate.post('/api/v1/emails/all-emails?limit=100&skip=0', {},
          { headers: { 'Content-Type': 'application/json' } });
        console.log(emailsResponse.data)
        if (emailsResponse.data.success) {
          setEmailsArray(emailsResponse.data.data);

          dispatch(updateData(emailsResponse.data.data));
        }
        setLoadingIndicatorActive(false);
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
        setLoadingIndicatorActive(false);
      }
    }
    if (!loggedIn) {
      navigate("/login");
    } else {
      getEmails();
    }
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Emails" title="ALL" />
      {/* Bulk Add Bot User Modal */}
      {/* Bulk Add Bot User Modal */}
      <Modal
        id="resetInUseEmailsModal"
        open={openResetInUseEmailsModal}
        onClose={handleCloseResetInUseEmailsModal}
        aria-labelledby="reset-in-use-emails-modal-title"
        aria-describedby="reset-in-use-emails-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="reset-in-use-emails-modal-title" variant="h6" component="h2">
            Reset In Use Emails
          </Typography>
          <Typography id="reset-in-use-email-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to reset the in use emails?
          </Typography>
          <Box className='flex-wrap mt-5' >
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseResetInUseEmailsModal}>Cancel</Button>
              <button
                type="button"
                id="resetInUseEmailsButton"
                onClick={() => resetInUseEmails()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Reset In Use Emails
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      <Modal
        id="bulkAddEmailsModal"
        open={openBulkAddEmailsModal}
        onClose={handleCloseBulkAddEmailsModal}
        aria-labelledby="bulk-add-emails-modal-title"
        aria-describedby="bulk-add-emails-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="bulk-add-emails-modal-title" variant="h6" component="h2">
            Bulk Add Emails
          </Typography>
          <Typography id="bulk-add-email-modal-description" sx={{ mt: 2 }}>
            Add a CSV file of new Emails (Email and Provider).
          </Typography>
          <Box className='flex-wrap mt-5' >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <input type="file" onChange={handleChangeFileUpload} />
            </FormControl>
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseBulkAddEmailsModal}>Cancel</Button>
              <button
                type="button"
                id="bulkAddEmailsButton"
                onClick={() => bulkAddEmails()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Upload Emails
              </button>
            </div>
          </Box>
        </Box>
      </Modal>

      <Modal
        id="bulkAddEmailsIRModal"
        open={openBulkAddEmailsIRModal}
        onClose={handleCloseBulkAddEmailsIRModal}
        aria-labelledby="bulk-add-emails-ir-modal-title"
        aria-describedby="bulk-add-emails-ir-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="bulk-add-emails-ir-modal-title" variant="h6" component="h2">
            Bulk Add IR Emails
          </Typography>
          <Typography id="bulk-add-email-ir-modal-description" sx={{ mt: 2 }}>
            Add a CSV file of new Emails (Email and Provider).
          </Typography>
          <Box className='flex-wrap mt-5' >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <input type="file" onChange={handleChangeFileUpload} />
            </FormControl>
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseBulkAddEmailsIRModal}>Cancel</Button>
              <button
                type="button"
                id="bulkAddEmailsIRButton"
                onClick={() => bulkAddEmailsIR()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Upload Emails
              </button>
            </div>
          </Box>
        </Box>
      </Modal>

      <Modal
        id="bulkAddEmailsMarketBeatModal"
        open={openBulkAddEmailsMarketBeatModal}
        onClose={handleCloseBulkAddEmailsMarketBeatModal}
        aria-labelledby="bulk-add-emails-market-beat-modal-title"
        aria-describedby="bulk-add-emails-market-beat-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="bulk-add-emails-market-beat-modal-title" variant="h6" component="h2">
            Bulk Add Market Beat Emails
          </Typography>
          <Typography id="bulk-add-email-market-beat-modal-description" sx={{ mt: 2 }}>
            Add a CSV file of new Emails (Email and Provider).
          </Typography>
          <Box className='flex-wrap mt-5' >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <input type="file" onChange={handleChangeFileUpload} />
            </FormControl>
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseBulkAddEmailsMarketBeatModal}>Cancel</Button>
              <button
                type="button"
                id="bulkAddEmailsMarketBeatButton"
                onClick={() => bulkAddEmailsMarketBeat()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Upload Emails
              </button>
            </div>
          </Box>
        </Box>
      </Modal>

      <Modal
        id="bulkAddEmailsLMKRCBModal"
        open={openBulkAddEmailsLMKRCBModal}
        onClose={handleCloseBulkAddEmailsLMKRCBModal}
        aria-labelledby="bulk-add-emails-lmk-rcb-modal-title"
        aria-describedby="bulk-add-emails-lmk-rcb-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="bulk-add-emails-lmk-rcb-modal-title" variant="h6" component="h2">
            Bulk Add LMK RCB Emails
          </Typography>
          <Typography id="bulk-add-email-lmk-rcb-modal-description" sx={{ mt: 2 }}>
            Add a CSV file of new Emails (Email and Provider).
          </Typography>
          <Box className='flex-wrap mt-5' >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <input type="file" onChange={handleChangeFileUpload} />
            </FormControl>
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseBulkAddEmailsLMKRCBModal}>Cancel</Button>
              <button
                type="button"
                id="bulkAddEmailsLMKRCBButton"
                onClick={() => bulkAddEmailsLMKRCB()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Upload Emails
              </button>
            </div>
          </Box>
        </Box>
      </Modal>

      <Modal
        id="bulkAddEmailsFINWSHModal"
        open={openBulkAddEmailsFINWSHModal}
        onClose={handleCloseBulkAddEmailsFINWSHModal}
        aria-labelledby="bulk-add-emails-fin-wsh-modal-title"
        aria-describedby="bulk-add-emails-fin-wsh-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="bulk-add-emails-fin-wsh-modal-title" variant="h6" component="h2">
            Bulk Add FIN WSH Emails
          </Typography>
          <Typography id="bulk-add-email-fin-wsh-modal-description" sx={{ mt: 2 }}>
            Add a CSV file of new Emails (Email and Provider).
          </Typography>
          <Box className='flex-wrap mt-5' >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <input type="file" onChange={handleChangeFileUpload} />
            </FormControl>
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseBulkAddEmailsFINWSHModal}>Cancel</Button>
              <button
                type="button"
                id="bulkAddEmailsFINWSHButton"
                onClick={() => bulkAddEmailsFINWSH()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Upload Emails
              </button>
            </div>
          </Box>
        </Box>
      </Modal>

      <Modal
        id="bulkAddEmailsFINPTTModal"
        open={openBulkAddEmailsFINPTTModal}
        onClose={handleCloseBulkAddEmailsFINPTTModal}
        aria-labelledby="bulk-add-emails-fin-ptt-modal-title"
        aria-describedby="bulk-add-emails-fin-ptt-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="bulk-add-emails-fin-ptt-modal-title" variant="h6" component="h2">
            Bulk Add FIN PTT Emails
          </Typography>
          <Typography id="bulk-add-email-fin-ptt-modal-description" sx={{ mt: 2 }}>
            Add a CSV file of new Emails (Email and Provider).
          </Typography>
          <Box className='flex-wrap mt-5' >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <input type="file" onChange={handleChangeFileUpload} />
            </FormControl>
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseBulkAddEmailsFINPTTModal}>Cancel</Button>
              <button
                type="button"
                id="bulkAddEmailsFINPTTButton"
                onClick={() => bulkAddEmailsFINPTT()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Upload Emails
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      {/* Delete Email Modal */}
      <Modal
        id="deleteEmailModal"
        open={openDeleteEmailModal}
        onClose={handleCloseDeleteEmailModal}
        aria-labelledby="delete-email-modal-title"
        aria-describedby="delete-email-modal-description"
      >
        <Box sx={modalInitialStyle}>
          <Typography id="delete-email-modal-title" variant="h6" component="h2">
            Delete Email
          </Typography>
          <Typography id="delete-email-modal-description" sx={{ mt: 2 }}>
            Are you sure you wish to remove this email? <span className='text-rose-600'>This action is irreversible.</span>
          </Typography>
          <Box className='flex-wrap mt-5' >
            <div className='flex justify-between'>
              <Button color='secondary' onClick={handleCloseDeleteEmailModal}>Cancel</Button>
              <button
                type="button"
                id="deleteEmailButton"
                onClick={() => deleteEmail()}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "10px" }}
                className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
              >
                Delete Email
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      {loadingIndicatorActive &&
        // display: 'flex', justifyContent: 'center', alignItems: 'center'
        <Box sx={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          position: "fixed",
          height: "100%",
          width: "100%",
          top: "0",
          left: "0",
          "backgroundColor": "rgba(255,255,255,0.5)",
          "z-index": "9999",
          "paddingTop": "10px"
        }} >
          <CircularProgress />
        </Box>
      }
      <div className='flex justify-end mb-10 -mt-20 mr-10 md:mr-20'>
        <TooltipComponent content="Add New Email" position="BottomCenter">
          <button
            type="button"
            id="addNewEmailButton"
            onClick={() => addNewEmail()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
          >
            <MdAdd />
          </button>
        </TooltipComponent>
        &nbsp;
        <TooltipComponent content="Bulk Add Emails" position="BottomCenter">
          <button
            type="button"
            id="bulkAddEmailsButton"
            onClick={() => handleOpenBulkAddEmailsModal()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray `}
          >
            <RiPlayListAddFill />
          </button>
        </TooltipComponent>
        &nbsp;
        <TooltipComponent content="Bulk Add Emails IR" position="BottomCenter">
          <button
            type="button"
            id="bulkAddEmailsIRButton"
            onClick={() => handleOpenBulkAddEmailsIRModal()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray `}
          >
            <RiPlayListAddFill />
          </button>
        </TooltipComponent>
        &nbsp;
        <TooltipComponent content="Bulk Add Emails Market Beat" position="BottomCenter">
          <button
            type="button"
            id="bulkAddEmailsMarketBeatButton"
            onClick={() => handleOpenBulkAddEmailsMarketBeatModal()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray `}
          >
            <RiPlayListAddFill />
          </button>
        </TooltipComponent>
        &nbsp;
        <TooltipComponent content="Bulk Add Emails LMK RCB" position="BottomCenter">
          <button
            type="button"
            id="bulkAddEmailsLMKRCBButton"
            onClick={() => handleOpenBulkAddEmailsLMKRCBModal()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray `}
          >
            <RiPlayListAddFill />
          </button>
        </TooltipComponent>
        &nbsp;
        <TooltipComponent content="Bulk Add Emails FIN WSH" position="BottomCenter">
          <button
            type="button"
            id="bulkAddEmailsFINWSHButton"
            onClick={() => handleOpenBulkAddEmailsFINWSHModal()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray `}
          >
            <RiPlayListAddFill />
          </button>
        </TooltipComponent>
        &nbsp;
        <TooltipComponent content="Bulk Add Emails FIN PTT" position="BottomCenter">
          <button
            type="button"
            id="bulkAddEmailsFINPTTButton"
            onClick={() => handleOpenBulkAddEmailsFINPTTModal()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray `}
          >
            <RiPlayListAddFill />
          </button>
        </TooltipComponent>
        &nbsp;
        <TooltipComponent content="Reset In Use Emails" position="BottomCenter">
          <button
            type="button"
            id="resetInUseEmailsDButton"
            onClick={() => handleOpenResetInUseEmailsModal()}
            style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
            className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
          >
            <MdAdd />
          </button>
        </TooltipComponent>
        &nbsp;
        <CSVLink
          data={kaPropsUtils.getData(tableProps)}
          headers={tableProps.columns.map(c => ({ label: c.title, key: c.key }))}
          filename={new Date().toLocaleDateString() + '-Emails.csv'}
          enclosingCharacter={''}
          separator={';'}>
          <TooltipComponent content="Download .CSV" position="BottomCenter">
            <button
              type="button"
              style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
              className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
            >
              <FaFileCsv />
            </button>

          </TooltipComponent>
        </CSVLink>
      </div>

      <Table
        {...tableProps}
        dispatch={dispatch}
        childComponents={{
          noDataRow: {
            content: () => 'No Emails Found'
          },
          dataRow: {
            elementAttributes: (props) => ({
              onMouseEnter: (event, extendedEvent) => {
                const {
                  childProps: {
                    rowKeyValue,
                  },
                  dispatch,
                } = extendedEvent;
                dispatch({ type: ROW_MOUSE_ENTER, rowKeyValue });
              },
              onMouseLeave: (event, { dispatch }) => {
                dispatch({ type: ROW_MOUSE_LEAVE });
              }
            })
          },
          cell: {
            elementAttributes: () => ({
              // className: 'my-cell-class',
              onClick: (e, extendedEvent) => {
                // const { childProps: { dispatch } } = extendedEvent;
                // dispatch({ type: 'MY_CELL_onClick', ...{ extendedEvent }});
                if (extendedEvent.childProps.column.key !== "Actions") {
                  // console.log("clicked row " + extendedEvent.childProps.rowKeyValue)
                  // navigateToClientDetailsWithID(extendedEvent.childProps.rowKeyValue);
                }
              },
            })
          },
          cellText: {
            content: (props) => {
              switch (props.column.key) {
                case 'dateCreated': return (
                  formatDate(props.rowData.dateCreated)
                );
                case 'Actions': return (
                  <div className='flex'>
                    <TooltipComponent content="Edit Email" position="BottomCenter">
                      <button
                        type="button"
                        id="editEmailButton"
                        onClick={() => editEmail(props.rowData._id)}
                        style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
                        className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
                      >
                        <AiFillEdit />
                      </button>
                    </TooltipComponent>
                    &nbsp;
                    <TooltipComponent content="Delete Email" position="BottomCenter">
                      <button
                        type="button"
                        id="deleteEmailButton"
                        onClick={() => handleOpenDeleteEmailModal(props.rowData._id)}
                        style={{ backgroundColor: "#FF5C8E", color: "white", borderRadius: "50%" }}
                        className={`text p-3 hover:drop-shadow-xl hover:bg-light-gray`}
                      >
                        <AiFillDelete />
                      </button>
                    </TooltipComponent>
                  </div>
                );
              }
            }
          }
        }}
      />
    </div>
  );
};

export default Emails;

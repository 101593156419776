import React, { useEffect, useState } from 'react';
import { BsListUl } from 'react-icons/bs';
import { VscSymbolKeyword } from 'react-icons/vsc';
import { FiUsers } from 'react-icons/fi';
import { MdOutlineCampaign, MdDomain } from 'react-icons/md';

import { Button } from '../components';

import { useStateContext } from '../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const Dashboard = () => {
  const { currentColor, currentMode, loggedIn, admin } = useStateContext();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  let [adminDashboardData, setAdminDashboardData] = useState({
    clientUsersNumber: 0,
    clientsNumber: 0
  });

  useEffect(() => {
    const getDashboardData = async () => {
      console.log("in dashboard")
      try {
        let dashboardData;

        dashboardData = await axiosPrivate.post('/api/v1/admin-dashboard/data', {});
        console.log(dashboardData.data);
        if (dashboardData.data.success) {
          console.log("setting")
          setAdminDashboardData(dashboardData.data.data);
        }
      } catch (err) {
        if (!err?.response) {
          console.log(err.response);
        } else if (err.response?.status === 400) {

        } else if (err.response?.status === 401) {

        } else if (err.response?.status === 500) {

        } else {

        }
      }
    }
    if (!loggedIn) {
      navigate("/login");
    } else {
      getDashboardData();
    }
  }, [])


  return (
    <div className="mt-24">
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="flex m-3 flex-wrap justify-center gap-1 items-stretch">

          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
            <button
              type="button"
              style={{ color: "#03C9D7", backgroundColor: "#E5FAFB" }}
              className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
            >
              <MdDomain />
            </button>
            <div className="flex flex-col justify-between flex-grow">
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.unusedEmails}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">Unused Emails</p>
              </div>
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.inUseEmails}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">In Use Emails</p>
              </div>
            </div>
          </div>

          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
            <button
              type="button"
              style={{ color: "#03C9D7", backgroundColor: "#E5FAFB" }}
              className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
            >
              <MdDomain />
            </button>
            <div className="flex flex-col justify-between flex-grow">
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.unusedEmailsMarketBeat}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">Unused Emails Market Beat (SPM)</p>
              </div>
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.inUseEmailsMarketBeat}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">In Use Emails Market Beat (SPM)</p>
              </div>
            </div>
          </div>
          
          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
            <button
              type="button"
              style={{ color: "#03C9D7", backgroundColor: "#E5FAFB" }}
              className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
            >
              <MdDomain />
            </button>
            <div className="flex flex-col justify-between flex-grow">
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.unusedEmailsLMKRCB}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">Unused Emails LMK RCB</p>
              </div>
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.inUseEmailsLMKRCB}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">In Use Emails LMK RCB</p>
              </div>
            </div>
          </div>

          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
            <button
              type="button"
              style={{ color: "#03C9D7", backgroundColor: "#E5FAFB" }}
              className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
            >
              <MdDomain />
            </button>
            <div className="flex flex-col justify-between flex-grow">
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.unusedEmailsFINWSH}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">Unused Emails FIN WSH</p>
              </div>
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.inUseEmailsFINWSH}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">In Use Emails FIN WSH</p>
              </div>
            </div>
          </div>

          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
            <button
              type="button"
              style={{ color: "#03C9D7", backgroundColor: "#E5FAFB" }}
              className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
            >
              <MdDomain />
            </button>
            <div className="flex flex-col justify-between flex-grow">
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.unusedEmailsFINPTT}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">Unused Emails FIN PTT</p>
              </div>
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.inUseEmailsFINPTT}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">In Use Emails FIN PTT</p>
              </div>
            </div>
          </div>

          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
            <button
              type="button"
              style={{ color: "#03C9D7", backgroundColor: "#E5FAFB" }}
              className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
            >
              <BsListUl />
            </button>
            <div className="flex flex-col justify-between flex-grow">
              <div>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{adminDashboardData.unusedPhones}</span>
                </p>
                <p className="text-sm text-gray-400 mt-1">Unused Phones</p>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Dashboard;
